// if (module.hot) {
//   module.hot.accept();
// }
import $ from 'jquery';
import AOS from 'aos';
AOS.init();

$(document).ready(() => {
  const header = $('.header');
  const burger = $('.hamburger');

  $(window).on('scroll', () => {
    if ($(window).width() > 995) {
      if ($(window).scrollTop() > 10) {
        header.addClass('fixed');
      } else {
        header.removeClass('fixed');
      }
    } else {
      $('.opacity-box').removeAttr('data-aos');
      $('.opacity-box').attr('data-aos', 'fade-right');
      $('.inputsmobile').attr('data-aos', 'fade-right');
      if ($(window).scrollTop() > $('.cta-section').height()) {
        header.addClass('fixed-mobile');
      } else {
        header.removeClass('fixed-mobile');
      }
    }
  });

  $('.nav-item span, .logo img, button, img').click(e => {
    let scrollTo = $(e.currentTarget).data('id');

    $('html, body').animate(
      {
        scrollTop: scrollTo !== 'nav' ? $(`#${scrollTo}`).offset().top : 0
      },
      1200
    );
  });

  burger.on('click', () => {
    $('.nav')
      .find('ul')
      .toggleClass('active');
    $('header').toggleClass('active');
    burger.toggleClass('active');
  });
});
